<template>
  <AppHeader />
  <Suspense>
    <template #default>
      <Contents :Content_id="Content_id"/>
    </template>
    <template #fallback>
      <Loding />
    </template>
  </Suspense>
  <AppFooter />
</template>

<script>
// @ is an alias to /src
import { defineAsyncComponent, Suspense } from "vue";
import Loding from "@/components/Include/Loding.vue";
import AppHeader from "@/components/Include/AppHeader.vue";
import AppFooter from "@/components/Include/AppFooter.vue";
const Contents = defineAsyncComponent(() =>
  import('@/components/Pages/Contents.vue')
)
export default {
  name: "Content",
  props: {
    Content_id: String,
  },
  components: {
    Suspense,
    Loding,
    AppHeader,
    AppFooter,
    Contents,
    
  },
};
</script>